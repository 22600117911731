export const ASSISTANT_TEXT = "Als Assistenz für";
export const REPRESENTATIVE_TEXT = "Als Vertretung für";
export function shouldRepresentativeRenderInHeader(displayLocation) {
    const validValues = ["header-and-sidebar", "header-only"];
    return validValues.includes(displayLocation);
}
export function shouldRepresentativeDisplayInSidebar(displayLocation) {
    const validValues = ["header-and-sidebar", "sidebar-only"];
    return validValues.includes(displayLocation);
}
